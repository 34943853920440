import React from "react";

const ToSContentZHHans = () => {
  return (
    <div>
      <h1>SGA使用者服务条款</h1>
      <p>
        SGA（以下简称「乙方」）及其关系企业兹提供网页、游戏、信息以及相关服务等（以下概括简称为「游戏服务」）供使用者（以下简称「甲方」）接触或使用。
      </p>
      <p>
        甲方使用游戏服务前应详阅本＜SGA使用者服务条款＞（以下简称「服务条款」），以保障甲方权益。本服务条款构成甲乙双方间契约，甲方于阅毕本服务条款内容并于确认页面按下「同意」选项或使用游戏服务，即视为甲方完全了解并同意本服务条款。
      </p>
      <p>
        乙方提供的游戏服务属于限制级，未满18岁之人不得以任何方式使用或观览。如您未满18岁，请立即离开游戏服务，若仍使用或观览，甲方应自行承担任何法律责任，乙方概不负责。
      </p>
      <h4>名词定义</h4>
      <li>1. 游戏：指由乙方或其关系企业提供之游戏以及与该游戏相关之服务。</li>
      <li>
        2.
        付费游戏点数：指甲方依照乙方或第三方游戏服务平台（以下简称「平台商」）所订付费方式，透过乙方或第三方游戏服务平台预付一定金额购买之虚拟货币，该虚拟货币得于游戏中作为支付工具使用（例如兑换游戏中虚拟道具、换取抽选虚拟道具或游戏角色机会、开启游戏中特定功能或用以解锁或扩充游戏关卡等）。
      </li>
      <li>
        3.
        非付费游戏点数：指甲方非透过支付价金方式获得之虚拟货币或道具（例如乙方因举办活动所发送或乙方因任何情况提供给甲方作为补偿之用者），该虚拟货币得于游戏中作为支付工具使用（例如兑换游戏中虚拟道具、换取抽选虚拟道具或游戏角色机会、开启游戏中特定功能或用以解锁或扩充游戏关卡等）。
      </li>
      <li>4. 游戏点数：指付费游戏点数及非付费游戏点数。</li>
      <li>
        5.
        虚拟道具：指可于游戏中使用之虚拟物品，甲方仅能在合乎法律和游戏管理规则的情况下拥有对虚拟道具的使用权。
      </li>
      <li>6. 游戏管理规则：系指由乙方订立且乙方有权修改，用以规范游戏进行方式之规则。</li>
      <li>
        7.
        游戏历程：指自甲方开始使用游戏服务起至结束使用游戏服务止，计算机系统对甲方游戏进行过程所为之记录。
      </li>
      <li>
        8.
        暂停游戏权限（冻结）：系指以限制登入游戏或暂时限制甲方游戏账号使用权利等方式，暂时禁止甲方或限缩甲方使用游戏服务。
      </li>
      <li>9. 插件：指非由乙方提供，以干预、影响、改变或不当纪录游戏服务运作之程序。</li>
      <li>10. 关系企业：指独立存在而相互间具有 (1)控制从属关系或 (2)相互投资关系之企业。</li>
      <h4>服务条款之内容</h4>
      以下项目视为本服务条款之一部分，与本服务条款具有相同之效力：
      <li>1. 乙方有关游戏之公告、活动规定或广告宣传内容。</li>
      <li>2. 乙方发布之各游戏管理规则。</li>
      <h4>退费申请限制</h4>
      <li>
        1.
        付费游戏点数一经甲方于游戏中使用（例如购买游戏中虚拟道具、换取抽选虚拟道具或角色之机会或用以解锁或扩充关卡等）即因其性质无法返还，不得申请退费。
      </li>
      <li>
        2.
        甲方所持有之非付费游戏点数及虚拟道具（包含因游戏过程中取得或甲方购买付费游戏点数时所并同赠送者）并无金钱价值，于任何情况下均不得申请退费。
      </li>
      <li>
        3.
        甲方如拟就未使用之付费游戏点数申请退费，应依据购买点数之第三方游戏平台商之程序及相关规定，自行向平台商申请及交涉，乙方无法退还游戏点数费用予甲方。
      </li>
      <h4>知识产权</h4>
      游戏服务、游戏及所有与乙方服务或游戏相关之著作、纪录或数据，其著作权、专利权、商标权、营业秘密、其他知识产权或申请知识产权之权利，所有权或其他权利（包含但不限于游戏、影片、程序代码、对象、场景、人物、名称、故事、对话、概念、图形、动画、声音、游戏历程等），均为乙方或其个别权利人所有。非经乙方或其个别权利人事先合法授权，甲方不得基于任何目的重制、传输、改作、编辑、以其他任何形式使用或为任何侵权行为，违者应负一切法律责任。但甲方参考游戏中素材所为之文字或视觉美术二次创作，在满足以下所有条件的情况下，甲方可以自由进行：
      <li>
        (1)
        甲方的创作不得直接或间接使用属于乙方之或第三方之著作物全部或一部，且甲方创作必须具有创作性质；
      </li>
      <li>
        (2)
        该创作仅用于甲方个人的非商业使用或甲方个人直接进行的小规模贩卖（即不透过第三方中介、委托贩卖、出版发行等情况）；
      </li>
      <li>
        (3)
        甲方必须充分揭露该项创作是乙方网站或游戏的二次创作，不得有使他人混淆误认为乙方官方作品之虞；
      </li>
      <li>(4) 该项创作或贩卖行为不至于损害乙方或其他权利人的商誉或任何权利。</li>
      <h4>账号密码之使用</h4>
      <li>
        1.
        甲方为登入游戏服务所使用之账号及密码，仅供甲方本人使用，不得将该组账号及密码转让、交付、揭露、出售或出借予第三人，亦不得使第三人使用该账号。违者乙方得冻结甲方游戏账号。如因而产生纠纷，由甲方自行负责。
      </li>
      <li>
        2.
        倘甲方系使用平台商之账号密码登入游戏服务，就甲方账号密码之管理、修改机制、交易纪录及电磁纪录等相关事项，包括甲方账号密码若遭非法使用之处理等，甲方应自行与平台商交涉，乙方概不负责。
      </li>
      <h4>电磁纪录</h4>
      甲方了解游戏服务之所有电磁纪录，仅为娱乐过程中提供之游戏乐趣与体验，并不具有金钱交易价值，甲方不得有移转、处分或收益之行为。
      <h4>个人资料保护与隐私权政策</h4>
      <li>
        1.
        关于甲方个人资料之保护，依个人资料保护法及相关法律规定处理。相关规定请参＜SGA个人资料暨隐私政策条款＞。
      </li>
      <li>
        2.
        如甲方未提供正确之个人资料或原提供之数据已不符合真实而未更新，乙方得于甲方提供真实数据或更新数据前，暂停游戏服务（包含但不限于暂停游戏权限）；如甲方未提供正确之个人资料或原提供之数据已不符合真实而未更新，乙方无须对甲方因本服务条款所发生的任何损害负责。
      </li>
      <li>
        3.
        甲方于游戏进行中如有任何违反法令或侵害他人权利之虞者，为配合主管机关或司法机关调查，乙方得将甲方个人资料、注册信息、游戏历程等提供该当主管机关。
      </li>
      <h4>公开与授权</h4>
      <li>
        1.
        乙方服务可能提供甲方表述之平台（包含游戏中对话、评论功能、上传图像等）。乙方无法保证甲方于公开场合之表述不被第三人使用，如因第三人未经授权使用甲方表述致甲方受有损害或产生任何争议，甲方应自行负责。
      </li>
      <li>
        2.
        甲方对其透过乙方服务所为之公开表述应自行承担一切法律责任，乙方无事先审查之义务，但乙方有权修改或删除不当表述，包含侵害他人著作权、歧视性、侮辱性、争议性、猥亵性、不雅性、违反本服务条款或违反法律之发言等。
      </li>
      <li>
        3.
        乙方有权监控或记录甲方透过使用游戏服务进行沟通之内容，甲方应同意并知悉甲方透过使用游戏服务进行之沟通并不属于甲方之个人隐私。乙方有权以任何理由揭露甲方的沟通内容，包括但不限于：(1)依据相关法律规定；
        (2)行政机关或司法机关要求揭露；(3)为执行本服务条款或其他任何游戏规范；(4)为保障乙方的合法权益和救济途径。
      </li>
      <li>
        4.
        甲方同意无偿、永久、不可撤回地授权乙方或其指定第三人于全球以任意方式利用其透过游戏服务所为之公开表述（包含重制、改作、出版、发行、公开发表等所有著作权法上得授权之权利）。
      </li>
      <h4>联机质量</h4>
      <li>
        1.
        甲方使用游戏服务必须自行配备上网所需之各项设备，以及负担「网际网络连接费用」等电讯相关费用。
      </li>
      <li>
        2.
        有关甲方使用电信事业之电信机线设备，如因电信机线设备之障碍或阻断，致发生错误、迟滞、中断或不能传递等非乙方所能控制之情况致造成损害时，乙方将不负赔偿责任。
      </li>
      <h4>双方权利义务</h4>
      <li>
        1. 甲方因共享账号、委托他人付费购买游戏点数等衍生与第三人间之纠纷，乙方得不予协助处理。
      </li>
      <li>
        2.
        乙方不担保游戏服务能运行于所有版本之行动装置操作系统（包含但不限于iOS系统或Android系统）或其他任何装置或操作系统。
      </li>
      <h4>游戏管理规则</h4>
      <li>1. 为规范游戏进行之方式，甲方应遵守乙方公告之游戏管理规则。</li>
      <li>2. 游戏管理规则之变更应依本服务条款第十二条之程序为之。</li>
      <li>3. 甲方若违反游戏管理规则，乙方得按其情节轻重限制甲方之游戏使用权利或暂停游戏权限。</li>
      <h4>服务条款之变更</h4>
      服务条款最新版本以充分保障其权利。若甲方于本服务条款修改或变更后，仍继续使用游戏服务，视为甲方已同意接受本服务条款之修改及变更。
      <h4>服务条款之终止</h4>
      <li>
        1.
        若甲方逾一年期间未登入使用乙方游戏服务，乙方得通知甲方于十五日内登入，如甲方届期仍未登入使用，则乙方得终止本服务条款。
      </li>
      <li>
        2.
        甲方有下列重大情事之一者，乙方得立即终止提供甲方游戏服务，且不负任何返还价金或损害赔偿责任：
      </li>
      <ul>
        <li>(1) 利用任何系统或工具对乙方计算机系统之恶意攻击或破坏。 </li>
        <li>
          (2)
          以利用插件、病毒程序、游戏程序漏洞或其他违反游戏常态设定或公平合理之方式进行游戏，包括但不限于：使用作弊程序、利用自动化执行软件（bots）自动控制游戏、服务器或任何组件或其功能、使用黑客程序或任何其他未经授权之第三方软件。
        </li>
        <li>(3) 以冒名、诈骗或其他虚伪不正等方式购买付费游戏点数。</li>
        <li>(4) 因同一事由违反游戏管理规则达三次数以上。</li>
        <li>(5) 经司法机关查获从事任何不法之行为。</li>
        <li>(6) 实施反向工程、反编译、拆解、破译或类似行为者。</li>
        <li>
          (7)
          利用游戏服务之一部或全部作为任何未经乙方授权的商业用途，包括但不限于透过游戏服务从事任何商业广告或营销活动。
        </li>
        <li>(8) 使用任何第三方软件或以其他不法方式取得来自或储存于乙方客户端或游戏之信息。</li>
        <li>
          (9)
          提供任何游戏配对服务，对乙方通讯协议进行拦截、仿真或重新导向，包括但不限于在因特网、网络联机机器或于内容汇聚网络进行游戏。
        </li>
        <li>
          (10)
          未经乙方授权或同意协助、建立或维护任何连接至游戏的联机，包括但不限于连接至未经授权服务器的任何模拟或试图模拟之联机，或其他任何游戏；以及使用未经授权程序或工具的任何联机。
        </li>
        <li>(11) 未经乙方授权而出售、出租、设定担保权益或转让甲方就游戏服务所持有之权利予他人。</li>
        <li>
          (12) 中断或协助中断任何用以支持游戏或任何游戏环境之计算机或者任何其他玩家的游戏过程。
        </li>
        <li>
          (13)
          发布任何辱骂、威胁、诽谤、侮辱、歧视性、猥亵性、攻击性言论、利用游戏从事不法行为、或意图骚扰、伤害任何人，情节重大者。
        </li>
        <li>(14) 公开或私下传播任何存在并可利用的游戏漏洞者。</li>
      </ul>
      <li>3.倘本服务条款终止后甲方拟申请退费，依本服务条款第三条办理。</li>
      <h4>送达</h4>
      <li>
        1. 有关本服务条款所有事项之通知，甲方同意乙方以甲方登录之联络地址或电子邮件地址为送达。
      </li>
      <li>
        2.
        前项登录地址或电子邮件地址若有变更，甲方应即通知乙方。乙方应依变更后之地址或电子邮件地址为送达。
      </li>
      <li>
        3.
        乙方依本条甲方登录之地址所为之通知发出后，以书面通知到达甲方，或电子邮件进入甲方之电子邮件服务器中，推定为已送达。
      </li>
      <li>4. 因甲方之故意或过失致乙方无法送达者，乙方对甲方因无法送达所致之损害不负赔偿责任。</li>
      <h4>语言</h4>
      本服务条款中文以外版本均仅供甲方参考之用，如其他语言的版本与中文版有不一致的地方，一切皆以中文版本为准。
      <h4>准据法及管辖法院</h4>
      <li>
        1.
        本服务条款、游戏管理规则及相关事项之解释与适用，以及甲方因使用游戏服务而与乙方间所生之权利义务关系，应以中华民国法律为准据法。
      </li>
      <li>2. 因本服务条款而生之事件，双方合意以台湾台北地方法院为第一审管辖法院。</li>
      <h4>联络方式</h4>
      如甲方对本服务条款有任何疑问，请透过电子邮件service@sg-arts.com与乙方联系。
      <p>最后更新日期：2020年12月7日</p>
    </div>
  );
};

export default ToSContentZHHans;
