import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import ToSContentZHHant from "./ToSContentZHHant";
import ToSContentZHHans from "./ToSContentZHHans";
import ToSContentEN from "./ToSContentEN";
import { BgWrapper, PageWrapper, ContentWrapper } from "../../components";

export default function ToSContentContent() {
  const intl = useIntl();
  const { locale } = intl;

  const getContent = () => {
    switch (locale) {
      case "zh-Hant":
        return <ToSContentZHHant />;
      case "zh-Hans":
        return <ToSContentZHHans />;
      case "ja":
      case "ko":
      case "en":
      default:
        return <ToSContentEN />;
    }
  };

  return (
    <BgWrapper>
      <PageWrapper>
        <ContentWrapper>{getContent()}</ContentWrapper>
      </PageWrapper>
    </BgWrapper>
  );
}
