import React from "react";

const ToSContentZHHant = () => {
  return (
    <div>
      <h1>SGA使用者服務條款</h1>
      <p>
        SGA（以下簡稱「乙方」）及其關係企業茲提供網頁、遊戲、資訊以及相關服務等（以下概括簡稱為「遊戲服務」）供使用者（以下簡稱「甲方」）接觸或使用。
      </p>
      <p>
        甲方使用遊戲服務前應詳閱本＜SGA使用者服務條款＞（以下簡稱「服務條款」），以保障甲方權益。本服務條款構成甲乙雙方間契約，甲方於閱畢本服務條款內容並於確認頁面按下「同意」選項或使用遊戲服務，即視為甲方完全了解並同意本服務條款。
      </p>
      <p>
        乙方提供的遊戲服務屬於限制級，未滿18歲之人不得以任何方式使用或觀覽。如您未滿18歲，請立即離開遊戲服務，若仍使用或觀覽，甲方應自行承擔任何法律責任，乙方概不負責。
      </p>
      <h4>名詞定義</h4>
      <li>1. 遊戲：指由乙方或其關係企業提供之遊戲以及與該遊戲相關之服務。</li>
      <li>
        2.
        付費遊戲點數：指甲方依照乙方或第三方遊戲服務平台（以下簡稱「平台商」）所訂付費方式，透過乙方或第三方遊戲服務平台預付一定金額購買之虛擬貨幣，該虛擬貨幣得於遊戲中作為支付工具使用（例如兌換遊戲中虛擬道具、換取抽選虛擬道具或遊戲角色機會、開啟遊戲中特定功能或用以解鎖或擴充遊戲關卡等）。
      </li>
      <li>
        3.
        非付費遊戲點數：指甲方非透過支付價金方式獲得之虛擬貨幣或道具（例如乙方因舉辦活動所發送或乙方因任何情況提供給甲方作為補償之用者），該虛擬貨幣得於遊戲中作為支付工具使用（例如兌換遊戲中虛擬道具、換取抽選虛擬道具或遊戲角色機會、開啟遊戲中特定功能或用以解鎖或擴充遊戲關卡等）。
      </li>
      <li>4. 遊戲點數：指付費遊戲點數及非付費遊戲點數。</li>
      <li>
        5.
        虛擬道具：指可於遊戲中使用之虛擬物品，甲方僅能在合乎法律和遊戲管理規則的情況下擁有對虛擬道具的使用權。
      </li>
      <li>6. 遊戲管理規則：係指由乙方訂立且乙方有權修改，用以規範遊戲進行方式之規則。</li>
      <li>
        7.
        遊戲歷程：指自甲方開始使用遊戲服務起至結束使用遊戲服務止，電腦系統對甲方遊戲進行過程所為之記錄。
      </li>
      <li>
        8.
        暫停遊戲權限（凍結）：係指以限制登入遊戲或暫時限制甲方遊戲帳號使用權利等方式，暫時禁止甲方或限縮甲方使用遊戲服務。
      </li>
      <li>9. 外掛程式：指非由乙方提供，以干預、影響、改變或不當紀錄遊戲服務運作之程式。</li>
      <li>10. 關係企業：指獨立存在而相互間具有 (1)控制從屬關係或 (2)相互投資關係之企業。</li>
      <h4>服務條款之內容</h4>
      以下項目視為本服務條款之一部分，與本服務條款具有相同之效力：
      <li>1. 乙方有關遊戲之公告、活動規定或廣告宣傳內容。</li>
      <li>2. 乙方發布之各遊戲管理規則。</li>
      <h4>退費申請限制</h4>
      <li>
        1.
        付費遊戲點數一經甲方於遊戲中使用（例如購買遊戲中虛擬道具、換取抽選虛擬道具或角色之機會或用以解鎖或擴充關卡等）即因其性質無法返還，不得申請退費。
      </li>
      <li>
        2.
        甲方所持有之非付費遊戲點數及虛擬道具（包含因遊戲過程中取得或甲方購買付費遊戲點數時所併同贈送者）並無金錢價值，於任何情況下均不得申請退費。
      </li>
      <li>
        3.
        甲方如擬就未使用之付費遊戲點數申請退費，應依據購買點數之第三方遊戲平台商之程序及相關規定，自行向平台商申請及交涉，乙方無法退還遊戲點數費用予甲方。
      </li>
      <h4>智慧財產權</h4>
      遊戲服務、遊戲及所有與乙方服務或遊戲相關之著作、紀錄或資料，其著作權、專利權、商標權、營業秘密、其他智慧財產權或申請智慧財產權之權利，所有權或其他權利（包含但不限於遊戲、影片、程式碼、物件、場景、人物、名稱、故事、對話、概念、圖形、動畫、聲音、遊戲歷程等），均為乙方或其個別權利人所有。非經乙方或其個別權利人事先合法授權，甲方不得基於任何目的重製、傳輸、改作、編輯、以其他任何形式使用或為任何侵權行為，違者應負一切法律責任。但甲方參考遊戲中素材所為之文字或視覺美術二次創作，在滿足以下所有條件的情況下，甲方可以自由進行：
      <li>
        (1)
        甲方的創作不得直接或間接使用屬於乙方之或第三方之著作物全部或一部，且甲方創作必須具有創作性質；
      </li>
      <li>
        (2)
        該創作僅用於甲方個人的非商業使用或甲方個人直接進行的小規模販售（即不透過第三方仲介、委託販賣、出版發行等情況）；
      </li>
      <li>
        (3)
        甲方必須充分揭露該項創作是乙方網站或遊戲的二次創作，不得有使他人混淆誤認為乙方官方作品之虞；
      </li>
      <li>(4) 該項創作或販售行為不至於損害乙方或其他權利人的商譽或任何權利。</li>
      <h4>帳號密碼之使用</h4>
      <li>
        1.
        甲方為登入遊戲服務所使用之帳號及密碼，僅供甲方本人使用，不得將該組帳號及密碼轉讓、交付、揭露、出售或出借予第三人，亦不得使第三人使用該帳號。違者乙方得凍結甲方遊戲帳號。如因而產生糾紛，由甲方自行負責。
      </li>
      <li>
        2.
        倘甲方係使用平台商之帳號密碼登入遊戲服務，就甲方帳號密碼之管理、修改機制、交易紀錄及電磁紀錄等相關事項，包括甲方帳號密碼若遭非法使用之處理等，甲方應自行與平台商交涉，乙方概不負責。
      </li>
      <h4>電磁紀錄</h4>
      甲方了解遊戲服務之所有電磁紀錄，僅為娛樂過程中提供之遊戲樂趣與體驗，並不具有金錢交易價值，甲方不得有移轉、處分或收益之行為。
      <h4>個人資料保護與隱私權政策</h4>
      <li>
        1.
        關於甲方個人資料之保護，依個人資料保護法及相關法律規定處理。相關規定請參＜SGA個人資料暨隱私政策條款＞。
      </li>
      <li>
        2.
        如甲方未提供正確之個人資料或原提供之資料已不符合真實而未更新，乙方得於甲方提供真實資料或更新資料前，暫停遊戲服務（包含但不限於暫停遊戲權限）；如甲方未提供正確之個人資料或原提供之資料已不符合真實而未更新，乙方無須對甲方因本服務條款所發生的任何損害負責。
      </li>
      <li>
        3.
        甲方於遊戲進行中如有任何違反法令或侵害他人權利之虞者，為配合主管機關或司法機關調查，乙方得將甲方個人資料、註冊資訊、遊戲歷程等提供該當主管機關。
      </li>
      <h4>公開與授權</h4>
      <li>
        1.
        乙方服務可能提供甲方表述之平台（包含遊戲中對話、評論功能、上傳圖像等）。乙方無法保證甲方於公開場合之表述不被第三人使用，如因第三人未經授權使用甲方表述致甲方受有損害或產生任何爭議，甲方應自行負責。
      </li>
      <li>
        2.
        甲方對其透過乙方服務所為之公開表述應自行承擔一切法律責任，乙方無事先審查之義務，但乙方有權修改或刪除不當表述，包含侵害他人著作權、歧視性、侮辱性、爭議性、猥褻性、不雅性、違反本服務條款或違反法律之發言等。
      </li>
      <li>
        3.
        乙方有權監控或記錄甲方透過使用遊戲服務進行溝通之内容，甲方應同意並知悉甲方透過使用遊戲服務進行之溝通並不屬於甲方之個人隱私。乙方有權以任何理由揭露甲方的溝通内容，包括但不限於：(1)依據相關法律規定；
        (2)行政機關或司法機關要求揭露；(3)為執行本服務條款或其他任何遊戲規範；(4)為保障乙方的合法權益和救濟途徑。
      </li>
      <li>
        4.
        甲方同意無償、永久、不可撤回地授權乙方或其指定第三人於全球以任意方式利用其透過遊戲服務所為之公開表述（包含重製、改作、出版、發行、公開發表等所有著作權法上得授權之權利）。
      </li>
      <h4>連線品質</h4>
      <li>
        1.
        甲方使用遊戲服務必須自行配備上網所需之各項設備，以及負擔「網際網絡連線費用」等電訊相關費用。
      </li>
      <li>
        2.
        有關甲方使用電信事業之電信機線設備，如因電信機線設備之障礙或阻斷，致發生錯誤、遲滯、中斷或不能傳遞等非乙方所能控制之情況致造成損害時，乙方將不負賠償責任。
      </li>
      <h4>雙方權利義務</h4>
      <li>
        1. 甲方因共用帳號、委託他人付費購買遊戲點數等衍生與第三人間之糾紛，乙方得不予協助處理。
      </li>
      <li>
        2.
        乙方不擔保遊戲服務能運行於所有版本之行動裝置作業系統（包含但不限於iOS系統或Android系統）或其他任何裝置或作業系統。
      </li>
      <h4>遊戲管理規則</h4>
      <li>1. 為規範遊戲進行之方式，甲方應遵守乙方公告之遊戲管理規則。</li>
      <li>2. 遊戲管理規則之變更應依本服務條款第十二條之程序為之。</li>
      <li>3. 甲方若違反遊戲管理規則，乙方得按其情節輕重限制甲方之遊戲使用權利或暫停遊戲權限。</li>
      <h4>服務條款之變更</h4>
      服務條款最新版本以充分保障其權利。若甲方於本服務條款修改或變更後，仍繼續使用遊戲服務，視為甲方已同意接受本服務條款之修改及變更。
      <h4>服務條款之終止</h4>
      <li>
        1.
        若甲方逾一年期間未登入使用乙方遊戲服務，乙方得通知甲方於十五日內登入，如甲方屆期仍未登入使用，則乙方得終止本服務條款。
      </li>
      <li>
        2.
        甲方有下列重大情事之一者，乙方得立即終止提供甲方遊戲服務，且不負任何返還價金或損害賠償責任：
      </li>
      <ul>
        <li>(1) 利用任何系統或工具對乙方電腦系統之惡意攻擊或破壞。 </li>
        <li>
          (2)
          以利用外掛程式、病毒程式、遊戲程式漏洞或其他違反遊戲常態設定或公平合理之方式進行遊戲，包括但不限於：使用作弊程式、利用自動化執行軟體（bots）自動控制遊戲、服務器或任何組件或其功能、使用駭客程式或任何其他未經授權之第三方軟體。
        </li>
        <li>(3) 以冒名、詐騙或其他虛偽不正等方式購買付費遊戲點數。</li>
        <li>(4) 因同一事由違反遊戲管理規則達三次數以上。</li>
        <li>(5) 經司法機關查獲從事任何不法之行為。</li>
        <li>(6) 實施反向工程、反編譯、拆解、破譯或類似行為者。</li>
        <li>
          (7)
          利用遊戲服務之一部或全部作為任何未經乙方授權的商業用途，包括但不限於透過遊戲服務從事任何商業廣告或行銷活動。
        </li>
        <li>(8) 使用任何第三方軟體或以其他不法方式取得來自或儲存於乙方客戶端或遊戲之資訊。</li>
        <li>
          (9)
          提供任何遊戲配對服務，對乙方通訊協定進行攔截、模擬或重新導向，包括但不限於在網際網路、網路連線機器或於內容匯聚網路進行遊戲。
        </li>
        <li>
          (10)
          未經乙方授權或同意協助、建立或維護任何連接至遊戲的連線，包括但不限於連接至未經授權伺服器的任何模擬或試圖模擬之連線，或其他任何遊戲；以及使用未經授權程式或工具的任何連線。
        </li>
        <li>(11) 未經乙方授權而出售、出租、設定擔保權益或轉讓甲方就遊戲服務所持有之權利予他人。</li>
        <li>(12) 中斷或協助中斷任何用以支援遊戲或任何遊戲環境之電腦或者任何其他玩家的遊戲過程。</li>
        <li>
          (13)
          發布任何辱罵、威脅、誹謗、侮辱、歧視性、猥褻性、攻擊性言論、利用遊戲從事不法行為、或意圖騷擾、傷害任何人，情節重大者。
        </li>
        <li>(14) 公開或私下傳播任何存在並可利用的遊戲漏洞者。</li>
      </ul>
      <li>3.倘本服務條款終止後甲方擬申請退費，依本服務條款第三條辦理。</li>
      <h4>送達</h4>
      <li>
        1. 有關本服務條款所有事項之通知，甲方同意乙方以甲方登錄之聯絡地址或電子郵件地址為送達。
      </li>
      <li>
        2.
        前項登錄地址或電子郵件地址若有變更，甲方應即通知乙方。乙方應依變更後之地址或電子郵件地址為送達。
      </li>
      <li>
        3.
        乙方依本條甲方登錄之地址所為之通知發出後，以書面通知到達甲方，或電子郵件進入甲方之電子郵件伺服器中，推定為已送達。
      </li>
      <li>4. 因甲方之故意或過失致乙方無法送達者，乙方對甲方因無法送達所致之損害不負賠償責任。</li>
      <h4>語言</h4>
      本服務條款中文以外版本均僅供甲方參考之用，如其他語言的版本與中文版有不一致的地方，一切皆以中文版本為準。
      <h4>準據法及管轄法院</h4>
      <li>
        1.
        本服務條款、遊戲管理規則及相關事項之解釋與適用，以及甲方因使用遊戲服務而與乙方間所生之權利義務關係，應以中華民國法律為準據法。
      </li>
      <li>2. 因本服務條款而生之事件，雙方合意以臺灣臺北地方法院為第一審管轄法院。</li>
      <h4>聯絡方式</h4>
      如甲方對本服務條款有任何疑問，請透過電子郵件service@sg-arts.com與乙方聯繫。
      <p>最後更新日期：2020年12月7日</p>
    </div>
  );
};

export default ToSContentZHHant;
