import React from "react";

const ToSContentEN = () => {
  return (
    <div>
      <h1>SGA Service Terms</h1>
      <p>
        SGA (hereinafter “SGA”) and its Affiliate provide website, games, information and related
        services (together hereinafter “Game Services”) for user’s (hereinafter “User”) approach and
        use.
      </p>
      <p>
        User shall read these ＜SGA Service Terms＞ (hereinafter “Service Terms”) carefully before
        using Game Services. These Service Terms establish a contractual relationship by and between
        SGA and User. By clicking the “Agree” option or using Game Services, User is deemed to have
        understood and agreed to the provisions of these Service Terms.
      </p>
      <p>
        The Game Services provided by SGA are rated “R” (Restricted). Any person under 18 shall not
        use or view Game Services in any way. If you are under 18, please leave Game Services
        immediately. If you continue to use or view Game Services, you shall solely be responsible
        for any legal consequences arising therefrom; SGA will not be liable in any way.
      </p>
      <h4>1. Definitions</h4>
      <li>
        1.1 “Affiliate” means enterprises which are independent in existence but are interrelated in
        either of the following relations: (A) having controlling and subordinate relation between
        SGA and such enterprise; or (B) having made investment in each other.
      </li>
      <li>
        1.2 “Game” means the game and the services related to such game provided by SGA and/or its
        Affiliates.
      </li>
      <li>
        1.3 “Gaming History” means User’s in-game progress recorded by the computer system from
        User’s login to logout of the Game Services.
      </li>
      <li>
        1.4 “Game Management Rules” means the rules established by SGA to regulate how the Game
        proceeds. The Game Management Rules may be amended by SGA from time to time.
      </li>
      <li>1.5 “Game Points” means both Purchased Game Points and Non-Purchased Game Points.</li>
      <li>
        1.6 “Non-Purchased Game Points” means the virtual currency acquired by User without paying
        money directly (for example, given by SGA as a favor of any event or compensation); and such
        Non-Purchased Game Points can be used as a payment instrument in the Game (for example,
        exchanging for Virtual Goods or opportunity to use loot boxes for Virtual Goods or
        characters, enabling certain function, or unlocking or extending game content).
      </li>
      <li>
        1.7 “Plugins” means the programs not provided by SGA, the purpose of which is to affect or
        change or record the operation of Game Services.
      </li>
      <li>
        1.8 “Purchased Game Points” means the virtual currency purchased by User via SGA or any
        third-party game services platforms (hereinafter “Third-party Platforms”) pursuant to the
        buying procedures stipulated by SGA or Third-party Platforms; and such Purchased Game Points
        can be used as a payment instrument in the Game (for example, exchanging for Virtual Goods
        or opportunity to use loot boxes for Virtual Goods or characters, enabling certain function,
        or unlocking or extending game content).
      </li>
      <li>
        1.9 “Temporary Restriction (freeze)” means a temporary ban on User’s right to use Game
        Services, for example, temporary restrict to login the Game or use User’s Game account.
      </li>
      <li>
        1.10 “Virtual Goods” means any virtual item in the Game. User only has the right to use such
        Virtual Goods under the law and Game Management Rules.
      </li>
      <h4>2. Contents</h4>
      The followings are considered as a part of these Service Terms:
      <li>
        2.1 Announcements, regulation of events, and advertisement relating to Game Services
        published or released by SGA.
      </li>
      <li>2.2 The Game Management Rules for each Game published or released by SGA.</li>
      <h4>3. Restrictions on Refund Applications</h4>
      <li>
        3.1 If the Purchased Game Points have been used by User (for example, exchanging for Virtual
        Goods or opportunity to use loot boxes for Virtual Goods or characters, enabling certain
        function, or unlocking or extending game content), such Purchased Game Points cannot be
        returned by its nature, and User may not request a refund in any reason by any means.
      </li>
      <li>
        3.2 The Virtual Goods and Non-Purchased Game Points (including those obtained during playing
        the Game or accompanied as gifts when buying Purchased Game Points) do not have a monetary
        or transaction value and are not refundable under any circumstances.
      </li>
      <li>
        3.3 If User intends to request a refund of unused Purchased Game Points, User shall follow
        the procedures and relevant rules announced by the Third-party Platforms from which User
        bought the Purchased Game Points. User shall deal with refund applications to such
        Third-party Platforms on his/her own, and SGA cannot refund the fees of Purchased Game
        Points to User.
      </li>
      <h4>4. Intellectual Property Rights</h4>
      All rights, titles and interest (including without limitation copyright, patent, trademark,
      trade secret and other rights) in and to Game Services and materials in relation to Game
      Services (including without limitation any games, titles, computer code, themes, scenes,
      objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork,
      animations, sounds, musical compositions, audio-visual effects, methods of operation, moral
      rights, documentation, in-game chat transcripts, character profile information, recordings of
      games, server software, and any other materials) are owned by SGA or the respective rights
      holders. SGA reserves all rights, including without limitation, all intellectual property
      rights or other proprietary rights, in connection with the Game and Game Services. User shall
      not reproduce, copy, transfer, transmit, convert, edit, make derivative work or commit any
      infringement by any means unless otherwise legally authorized in advance by SGA or the
      respective rights holders. Notwithstanding the foregoing, under following conditions, User may
      create visual art works or literature works by taking the components of the Game as reference:
      <li>
        (A) User may not use directly or indirectly any part of SGA’s or third parties’ works, and
        the works of User shall be creative;
      </li>
      <li>
        (B) User may only use such works for User’s personal non-commercial use or the small scale
        selling directly done by User (for example, not sold or published by third party);
      </li>
      <li>
        (C) User has to mark or indicate that such works are derivative works of SGA Websites or
        Game to prevent any potential misunderstanding that the User’s works are the official works
        of SGA; and
      </li>
      <li>
        (D) such works and the sale will not cause damage to or harm the reputation of SGA, the Game
        or the respective right holder.
      </li>
      <h4>5. Use of the Account and Password</h4>
      <li>
        5.1 The user account and password for which the User uses to login the Game Services, shall
        be used exclusively by User. User shall neither transfer, deliver, disclose, sell or lend
        them to any third party, nor let any other person to use or access User’s own account. If
        User
      </li>
      <li>
        5.2 If User uses the account registered in Third-party Platforms to login the Game Services,
        User shall negotiate with such Third-party Platforms on his/her own in respect of the
        management of the account, the changing mechanism, transaction history, electromagnetic
        record and relevant issues, including any unauthorized use of User’s account; SGA will not
        be responsible in any way.
      </li>
      <h4>6. Electromagnetic Record</h4>
      User understands that all electromagnetic records for Game Services are for User’s
      entertainment only and have no monetary or transaction value. User shall not transfer, dispose
      or make profits from the electromagnetic records.
      <h4>7. Personal Information and Privacy Protection Policy</h4>
      <li>
        7.1 The protection of User’s personal information will be handled in accordance with the
        Personal Information Protection Act and the related laws and regulations. Please refer to
        ＜SGA Personal Information and Privacy Policy＞ for further information.
      </li>
      <li>
        7.2 If User fails to provide correct personal information or if the provided information is
        no longer accurate and User fails to update such information, SGA may suspend User’s
        availability to Game Services (including but not limited to suspending any in-progress
        games) before the accurate or updated information is provided by User. If User provides
        incorrect personal information or if the provided information is no longer accurate and User
        fails to update such information, SGA shall not be responsible for any damages incurred by
        User under these Service Terms.
      </li>
      <li>
        7.3 If User’s actions regarding Game Services violate any laws and regulations or infringe
        SGA’s or any other parties’ rights, SGA may, in order to cooperate on the investigations of
        the public authorities or any government agency, provide User’s personal information,
        membership registration information and Gaming History to the relevant authority as
        evidence.
      </li>
      <h4>8. Publication and Authorization</h4>
      <li>
        8.1 SGA may provide platforms which User can make statements on it (including but not
        limited to dialogue in the Game, comments, uploaded images). SGA does not warrant that any
        public statement User made will not be used by a third party. If there is any unauthorized
        use of User’s statement by a third party, User shall be solely responsible to any damages or
        dispute arising therefrom.
      </li>
      <li>
        8.2 User shall be solely responsible to his/her public statement makes through Game
        Services. SGA has no obligation to prior review any User’s statement; provided that, SGA is
        entitled to edit or delete any improper statement (including but not limited to statements
        that infringe copyright, insulting, discriminating controversial, indecent, obscene or
        distributable statements, statements violate laws or these Service Terms) in its sole
        discretion.
      </li>
      <li>
        8.3 SGA is entitled to monitor or record the communication content of User made through Game
        Services. User acknowledges and agrees that User have no privacy to the communication
        content made by User through Game Services. SGA has the right to reveal the communication
        content made by User with any reason, including but not limited to
        <ul className="list__level--2">
          <li>(a) In accordance with the relevant laws and regulations;</li>
          <li>(b) Required by the administrative or judicial authority;</li>
          <li>
            (c) To execute any terms of these Service Term or other Game Management Rules; or (d) To
            protect the right and relief of SGA.
          </li>
        </ul>
      </li>
      <li>
        8.4 User agrees to grant SGA or its designated third party a royalty-free, perpetual, fully
        paid-up, worldwide, irrevocable license to use User’s statements, including copy, reproduce,
        fix, adapt, modify, create derivative works from, manufacture, commercialize, publish,
        distribute, transfer, lease, transmit, publicly display, publicly perform, or provide access
        to electronically, broadcast, communicate to the public by telecommunication, and use and
        practice, in any way, User’s statements as well as all modified and derivative works thereof
        in connection with Game Services. User also grants SGA any right which are licensable in
        conditions same as the aforementioned.
      </li>
      <h4>9. Internet Connection Quality</h4>
      <li>
        9.1 In order to use the Game Services, User shall equip with the required devices to connect
        to the internet and User shall be responsible for the internet fees and other
        telecommunication charges related thereto.
      </li>
      <li>
        9.2 SGA is not liable for any damages incurred as a result of connection error, sluggish
        connection, disconnection, or inability to transmit due to the obstruction or blockage of
        telecommunication facilities and equipment.
      </li>
      <h4>10. Obligations of the Parties</h4>
      <li>
        10.1 For any dispute between User and a third party arising out of sharing account or
        engaging third party purchasing Game Points, SGA is not obligated to provide any assistance.
      </li>
      <li>
        10.2 SGA does not guarantee that the Game is functional on all versions of mobile operating
        systems, including but not limited to iOS or Android, or any other device or operating
        system.
      </li>
      <h4>11. Game Management Rules</h4>
      <li>11.1 User shall comply with the Game Management Rules published by SGA.</li>
      <li>
        11.2 Any changes to the Game Management Rules shall follow the procedures set out in Article
        12 of these Service Terms.
      </li>
      <li>
        11.3 If User violates any of these Service Terms, SGA may, based on the violation degrees,
        restrict User's right to access Game Services or place a Temporary Restriction on User.
      </li>
      <h4>12. Amendment to Service Terms</h4>
      SGA is entitled to amend the Service Terms from time to time. User shall read these Service
      Terms regularly and comply with the same to protect his/her rights. By continuing to use the
      Game Services after these Service Terms are amended, User is deemed to have agreed to such
      amendments.
      <h4>13. Termination of the Service Terms</h4>
      <li>
        13.1 If User do not login and use Game Services for more than one year, SGA may notify User
        to login in fifteen days, and if User still fails to do so, SGA may terminate the Service
        Terms.
      </li>
      <li>
        13.2 SGA may immediately cease to provide Game Services to User if User behaves any of the
        following, and SGA will not be liable in any way nor be responsible for a refund:
        <ul className="list__level--2">
          <li>
            (A) attack, try to attack or destroy SGA’s computer system intentionally by using any
            system or device;
          </li>
          <li>
            (B) use plugins, malware, loopholes in the game, or other methods that are in violation
            of fairness and reasonableness in gaming process or affect on the ordinary game
            structure, including but not limited to: using cheats, automated software (bots) to
            automatically control the game, server, any module or its functions, hack programs, or
            any other unauthorized third party software;
          </li>
          <li>
            (C) purchase the Purchased Game Points in a way of counterfeiting the identity,
            committing fraud or any other false or improper method;
          </li>
          <li>
            (D) violate the Game Management Rules with the same reason for more than three times;
          </li>
          <li>
            (E) discovered by the judiciary to engage in any unlawful behavior; (F) do reverse
            engineering, decompile, disassemble, or anything alike;
          </li>
          <li>
            (G) use Game Services, in whole or in part, for any commercial purpose without getting
            prior authorization from SGA, including but not limited to undertaking any commercial
            advertisement or marketing activities through Game Services;
          </li>
          <li>
            (H) use any third party software or other illegal methods to obtain information from or
            stored at SGA customers’ end or any other Game Services’ information;
          </li>
          <li>
            (I) provide any game matching services that intercepts, simulates, or redirects SGA’s
            communication protocols, including but not limited to carrying on the Game through the
            internet, internet connection devices, or content aggregation networks;
          </li>
          <li>
            (J) assist, create, or maintain any connection to Game Services without SGA’s
            authorization or prior consent, including but not limited to any connection simulating
            or attempting to simulate a connection to an unauthorized server, or any other games;
            and any connection utilizing unauthorized programs or devices;
          </li>
          <li>
            (K) sell, lease, impose a lien on, or transfer the rights held by User with respect to
            the game to others without the prior authorization of SGA.
          </li>
          <li>
            (L) disrupt or assist in the disruption of any computer used to support Game Services or
            any gaming environment; or the gaming process of any other players;
          </li>
          <li>
            (M) post any information that is abusive, threatening, obscene, defamatory, libelous, or
            racially, sexually, religiously, or otherwise objectionable or offensive, or contains
            nudity, excessive violence, or offensive subject matter or that contains a link to such
            content; or
          </li>
          <li>
            (N) disclose any loophole or bug of Game which may be improper used by any third party
            in public or non-public manner.
          </li>
        </ul>
      </li>
      <li>
        13.3 If User intends to request a refund after the Service Terms are terminated, Article 3
        shall apply.
      </li>
      <h4>14. Delivery</h4>
      <li>
        14.1 User agrees that any notice from SGA in regard to these Service Terms shall be
        delivered to the contact address or email address provided by User.
      </li>
      <li>
        14.2 In the event of any changes to the provided addresses referred to in the Article 14.1,
        User shall notify SGA immediately. After SGA’s receipt, SGA shall deliver notices to the
        newly changed address accordingly.
      </li>
      <li>
        14.3 Once SGA delivers a notice to the address referred to in this Article 14, such notice
        shall be deemed to be delivered when the written notice reaches User’s mailbox or when the
        email enters into User’s email server.
      </li>
      <li>
        14.4 If SGA is unable to deliver the notice due to User’s willful or negligent act, SGA
        shall not be liable for the damages suffered by User from such delivery failure.
      </li>
      <h4>15. Language</h4>
      These Service Terms may be translated into different languages for User’s reference only. If
      there is any inconsistency or ambiguity between the Chinese version and the other language
      versions, the Chinese version shall prevail and govern.
      <h4>16. Governing Law and Jurisdiction</h4>
      <li>
        16.1 The interpretation and application of these Service Terms and the relevant Game
        Management Rules, as well as the rights and obligations between User and SGA arising from
        User’s use of Game Services, shall be governed by the laws of Taiwan, Republic of China.
      </li>
      <li>
        16.2 Both parties agree that Taipei District Court shall serve as the court of first
        instance for any disputes arising out of these Service Terms.
      </li>
      <h4>17. Contact Information</h4>
      If User has any question regarding these Service Terms, please email SGA at
      service@sg-arts.com.
      <p>Last Updated: December 7, 2020</p>
    </div>
  );
};

export default ToSContentEN;
